import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { BaseTitle, LgTitle, SmTitle } from '../elements/Titles';
import { BaseParagraph, XsParagraph } from '../elements/Paragraphs';

const MembershipAgreement = () => (
  <Layout>
    <SEO title="Subscription Agreement" />
    <Container>
      <Row>
        <Column>
          <LgTitle>Subscription Agreement</LgTitle>
          <XsParagraph>
            The quick reference is provided as a readers' aid — the full policy
            governs if there is any inconsistency.
          </XsParagraph>
        </Column>
      </Row>
      <Row hideMobile="true" textAlign="center">
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" marginBottom="0">
            The full policy
          </SmTitle>
        </Column>
        <Column width="1/3">
          <SmTitle dark="true" marginBottom="0">
            The quick reference
          </SmTitle>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>1. Effective Date</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Memberships after September 30, 2021 are governed by these terms.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            This policy takes effect on September 30, 2021.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>2. Overview</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Subscriptions are governed by these terms as well as the Site's other
            terms.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Wise Publishing Inc. offers enhanced Services for those who register
            as a Subscriber. Subscription Agreement are the terms and conditions of
            Subscribing. Subscribers are also governed by the Terms of Use of Wise
            Publishing, Inc.’s website (
            <Link to="/terms-of-use/">
              https://www.wisepublishing.com/terms-of-use/
            </Link>
            ) and other policies on the Wise Publishing, Inc. site. Wise
            Publishing, Inc. reserves the right in its sole discretion to reject
            a Subscriber at any time.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>3. Definitions</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            The definitions are here. If a term doesn't make sense, look here.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            The following words and expressions have the following meanings
            unless inconsistent with the context:
          </XsParagraph>
          <XsParagraph>
            "<strong>Content</strong>"
          </XsParagraph>
          <XsParagraph>
            means any media that can be rendered on a web page intended for
            consumption by an end-user, including, but not limited to articles,
            quizzes, polls, surveys, videos, pictures, audio and embeds;
          </XsParagraph>
          <XsParagraph>
            "<strong>Personal Information</strong>"
          </XsParagraph>
          <XsParagraph>
            means information about an identifiable individual. Personal
            Information includes facts which, when combined with other
            information, can identify an individual;
          </XsParagraph>
          <XsParagraph>
            "<strong>Subscription</strong>"
          </XsParagraph>
          <XsParagraph>
            Means subscribing for enhanced offerings through the Site;
          </XsParagraph>
          <XsParagraph>
            "<strong>Service</strong>"
          </XsParagraph>
          <XsParagraph>
            means a product offering on a Wise Publishing, Inc. website;
          </XsParagraph>
          <XsParagraph>
            "<strong>we</strong>", "<strong>us</strong>" or "
            <strong>our</strong>"
          </XsParagraph>
          <XsParagraph>
            Wise Publishing, Inc., a company duly incorporated under the laws of
            the province of Ontario;
          </XsParagraph>
          <XsParagraph>
            "<strong>you</strong>", "<strong>your</strong>"
          </XsParagraph>
          <XsParagraph>
            an individual, company or firm accessing our Site.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>4. Personal Information</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Personal information is governed by the Privacy Policy.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Personal Information provided by you to register as a subscriber is
            governed by the Wise Publishing, Inc. Privacy Policy located at{' '}
            <Link to="/privacy-policy/">
              https://www.wisepublishing.com/privacy-policy/
            </Link>
            .
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>5. Subscription Benefits</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Subscriber benefits are on the  subsciption landing page. You must be
            logged in as a subscriber to get them.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Subscribers have a host of benefits and services available, which may
            change from time-to-time. Benefits include Ad-free content* and a
            personalized weekly newsletter. The current Subscription Benefits are
            always available on the MoneyWise Subscription landing page.
          </XsParagraph>
          <XsParagraph>
            *Members must be logged in for display advertising to be disabled.
            Ad-free refers only to display advertising. Content will continue to
            contain affiliate offers.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>6. Age</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You must be of the age of majority in your jurisdiction of residence to register as a member.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You must be the age of majority.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>You must be the age of majority.</BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You must be of the age of majority in your jurisdiction of residence
            to register as a member.
          </XsParagraph>
        </Column>
      </Row>
     
      <Row>
        <Column>
          <BaseTitle>7. Modifications by Wise Publishing, Inc.</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If we modify membership terms, we will provide reasonable notice of
            the changes so you can change your membership.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You will be given advance notice of any material changes to the 
            Services by notification methods including but not limited to postal mail, email, or the
            posting of a notice on a website of Wise Publishing, Inc.
          </XsParagraph>
          <XsParagraph>
            If you do not wish to accept the new terms, you may cancel your
            Subscription.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>8. Cancellations</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            The process for cancelling subscriptions is here.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You may cancel a subscription at any time by
            contacting Wise Publishing, Inc. by email at
            hello@wisepublishing.com or by phone at +1 (647) 352-5595 between
            9:30 am and 5:00 pm Eastern, Monday through Friday.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>9. Promotions</BaseTitle>
        </Column>
      </Row>
      <Row marginBottom="144">
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Promotions will be governed by their own terms and rules.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Wise Publishing, Inc. may from time to time at its sole discretion
            offer promotions such as discounts and trials to certain Services,
            or certain contests, sweepstakes or other promotions. The specific
            rules, regulations, terms and details of each promotion will be
            provided at the time of the offer. Your eligibility for a promotion
            and the applicability of any discounts is at our sole discretion. We
            may revoke, terminate, cancel, modify or deem a promotion
            inapplicable to you at any time.
          </XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default MembershipAgreement;
